<template>
  <div class="battery">
    <div
      class="absolute top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12"
    ></div>
    <div class="px-4 md:px-10 mx-auto w-full">
      <div class="flex flex-wrap mt-4">
        <SlideUp>
          <ListTable
            :tableName="$t('battery')"
            :headers="headers"
            :tableData="$store.state.batteries.batteries"
            :loading="$store.state.batteries.loading"
            createName="Battery-Detail"
            qrType="battery"
          />
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from "@/components/ListTable.vue";
import SlideUp from "@/components/transitions/SlideUp.vue";

export default {
  name: "Battery",
  components: {
    ListTable,
    SlideUp
  },
  data() {
    return {
      headers: [
        {
          name: "#",
          key: "battery.id",
          type: "link",
          linkInfo: {
            name: "Battery-Detail",
            routeKey: "id",
            varKey: "battery.id"
          }
        },
        { name: "hub", key: "hub.name", type: "" },
        { name: "supplier", key: "supplier.name", type: "" },
        // { name: "Charging cycles", key: "number_of_charges", type: "" },
        {
          name: "Charging cycles",
          key: "number_of_charges",
          icon: "fas fa-battery-full"
        },
        { name: "Lifetime", key: "life_time", type: "percentTag", unit: "%" },
        { name: "notes", key: "battery.notes", type: "" }
        // "Battery ID", "Charging cycles", "Cycles left", "Lifetime"
      ]
      // drop 50% yellow
      // drop 10% red
      // green > 50%
    };
  },
  mounted() {
    this.$store.dispatch("batteries/getAllBatteries");
  }
};
</script>
