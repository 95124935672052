<template>
  <div class="battery">
    <div class="absolute top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="px-4 md:px-10 mx-auto w-full">
      <div class="flex flex-wrap mt-4">
        <SlideUp>
          <ListTable
            :tableName="$t('Supplier')"
            :headers="headers"
            :tableData="$store.state.suppliers.suppliers"
            :loading="$store.state.suppliers.loading"
            createName="Supplier-Detail"
          />
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from "@/components/ListTable.vue";
import SlideUp from "@/components/transitions/SlideUp.vue";

export default {
  name: "Supplier",
  components: {
    ListTable,
    SlideUp
  },
  data() {
    return {
      headers: [
        {
          name: "#",
          key: "id",
          type: "hidden",
          linkInfo: { name: "Supplier-Detail", routeKey: "id", varKey: "id" }
        },
        { name: "name", key: "name", type: "" },
        {
          name: "type",
          key: "type.name",
          type: "",
          filter: true
        },
        { name: "address", key: "address", type: "address" },
        {
          name: "actions",
          key: "action",
          type: "action",
          noSort: true,
          actions: [
            {
              name: "delete",
              icon: "far fa-trash-alt",
              action: (evt, supplier) => {
                evt.stopPropagation();

                const confirmStr = `${this.$t("Confirm delete supplier")}: ${
                  supplier.subject
                }`;
                this.$confirm(confirmStr).then(() => {
                  this.$store.dispatch("suppliers/deleteSupplier", {
                    supplier
                  });
                });
              }
            }
          ]
        }
      ]
    };
  },
  created() {
    this.$store.dispatch("suppliers/getAllSuppliers");
  }
};
</script>
