<template>
  <div class="hub">
    <div class="absolute top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="px-4 md:px-10 mx-auto w-full">
      <div class="flex flex-wrap mt-4">
        <SlideUp>
          <ListTable
            :tableName="$t('Hub')"
            :headers="headers"
            :tableData="$store.state.hubs.hubs"
            :loading="$store.state.hubs.loading"
            createName="Hub-Detail"
          />
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from "@/components/ListTable.vue";
import SlideUp from "@/components/transitions/SlideUp.vue";

export default {
  name: "Hub",
  components: {
    ListTable,
    SlideUp
  },
  data() {
    return {
      headers: [
        {
          name: "#",
          key: "id",
          type: "hidden",
          linkInfo: { name: "Hub-Detail", routeKey: "id", varKey: "id" }
        },
        { name: "name", key: "name", type: "" },
        {
          name: "type",
          key: "type.name",
          type: "",
          filter: true
        },
        { name: "street", key: "address.street", type: "" },
        { name: "city", key: "address.city", type: "", filter: true },
        { name: "state", key: "address.state", type: "", filter: true },
        { name: "country", key: "address.country", type: "", filter: true },
        {
          name: "actions",
          key: "action",
          type: "action",
          noSort: true,
          actions: [
            {
              name: "delete",
              icon: "far fa-trash-alt",
              action: (evt, hub) => {
                evt.stopPropagation();

                const confirmStr = `${this.$t("Confirm delete hub")}: ${
                  hub.name
                }`;
                this.$confirm(confirmStr).then(() => {
                  this.$store.dispatch("hubs/deleteHub", {
                    hub
                  });
                });
              }
            }
          ]
        }
        // { name: "address", key: "address", type: "address" }
      ]
    };
  },
  mounted() {
    this.$store.dispatch("hubs/getAllHubs");
  }
};
</script>
